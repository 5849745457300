








































import { Settings } from '@/services/SOLO';
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { eventHandler, translations } from '@/mixins'
@Component({
  mixins: [eventHandler, translations]
})
export default class CustomFieldsTable extends Vue {
  
  @Prop() items!: Array<Object>
  @Prop() categoryId!: string
  
  btnLoading!: Function
  btnRestore!: Function
  confirm!: Function
  translate!: Function
  $notify: any

  get deleteMsgBoxText(): String {
    return 'Are you sure you want to delete this custom field?'
  }

  modifyField(item: any) {
    console.log('CategoryCustomFields: ', item);
    this.$emit('fields:modify', item);
  }

  detachField(id: string) {
    let payload = {         
      entity: 'category',
      'entity-id': this.categoryId
    }        

    this.confirm(this.$bvModal, this.deleteMsgBoxText)
      .then((value: boolean) => {
        if(value) {          
          Settings.detachField(id, payload)
          .then((response: any) => {                        
            this.$emit('field:deleted', response.data.data)
            this.$notify({
              title: "DATA DELETED",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: 'Custom field successfully deleted',
              type: "success",
              icon: "fas fa-trash",
            })
          })
          .catch((err: any) => {                
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          })
        }
      })
  }
}
